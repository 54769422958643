/**
 * Created by avim on 12/27/15.
 */
define([
    'prop-types',
    'lodash',
    'componentsCore',
    'backgroundCommon',
    'components',
    'santa-components'
], function (
    PropTypes,
    _,
    componentsCore,
    backgroundCommon,
    components,
    santaComponents
) {
    'use strict';

    const mediaCommon = components.mediaCommon;

    const getRootStyle = (rootStyle, isMeshLayoutMechanism) => {
        rootStyle = rootStyle || {};

        return isMeshLayoutMechanism ?
            _.assign({}, rootStyle, {display: 'flex'}) :
            rootStyle;
    };

    const getContainerStyle = props => {
        if (props.isResponsive) {
            return {};
        }

        const containerStyle = _.clone(props.containerStyle);

        if (props.isMeshLayoutMechanism) {
            _.assign(containerStyle, {position: 'relative', height: '100%', width: '100%'});
        }

        if (_.isEmpty(containerStyle.borderWidth)) {
            return containerStyle;
        }

        const borderWidth = parseInt(containerStyle.borderWidth, 10);
        return _.assign({}, containerStyle, {'margin': `-${borderWidth}px`});
    };

    const getInlineParentStyle = (props, isFullscreenAndFixed) => {
        if (props.isResponsive) {
            return {};
        }

        const overflow = props.compProp.allowOverflowContent ? 'visible' : undefined;

        const inlineParentStyle = props.inlineParentStyle ?
            _.clone(props.inlineParentStyle) :
            _.assign({}, mediaCommon.defaultMediaStyle, props.bgStyle, overflow ? {overflow} : {});

        if (props.isMeshLayoutMechanism) {
            _.assign(inlineParentStyle, {position: 'relative'});
        }

        if (isFullscreenAndFixed) {
            // fix content in front of background in position:fixed disappearing when scrolling to it - Chromium +85 bug
            inlineParentStyle.backfaceVisibility = 'hidden';
        }

        return inlineParentStyle;
    };

    const getInlineStyle = props => {
        if (props.isResponsive) {
            return {};
        }

        const width = (props.rootStyle || props.style).width;
        const inlineStyle = props.inlineStyle ?
            _.clone(props.inlineStyle) :
            {
                width,
                position: 'absolute',
                top: 0,
                bottom: 0
            };

        if (props.isMeshLayoutMechanism) {
            _.assign(inlineStyle, {position: 'relative'});
        }

        return inlineStyle;
    };

    /**
     * Create a new MediaContainer component definition
     * @param options
     * @returns {{displayName: (*|string), mixins: [*,*,*], propTypes: {style: *, compDesign, rootStyle: *, bgStyle: *, inlineStyle: *}, statics: {behaviors: (exports.behaviorsAPI|{play, pause, stop, setVolume, mute, unMute, seek}|{play, pause, stop})}, getDefaultSkinName: components.MediaContainer.getDefaultSkinName, getSkinProperties: components.MediaContainer.getSkinProperties}}
     */
    function createMediaContainer(options) {
        options = options || {};
        const mixins = [
            mediaCommon.mediaLogicMixins.fill,
            componentsCore.mixins.skinBasedComp,
            componentsCore.mixins.createChildComponentMixin,
            santaComponents.mixins.inlineContentMixin,
            santaComponents.mixins.overflowWrapperMixin
        ];
        if (options.useBackgroundDetectionMixin) {
            mixins.push(backgroundCommon.mixins.backgroundDetectionMixin);
        }
        /**
         * @class components.MediaContainer
         * @extends {core.skinBasedComp}
         */
        return {
            displayName: options.displayName || 'MediaContainer',
            mixins,
            propTypes: {
                style: santaComponents.santaTypesDefinitions.Component.style.isRequired,
                compDesign: santaComponents.santaTypesDefinitions.Component.compDesign,
                compProp: santaComponents.santaTypesDefinitions.Component.compProp,
                isMobileView: santaComponents.santaTypesDefinitions.isMobileView.isRequired,
                isMeshLayoutMechanism: santaComponents.santaTypesDefinitions.Layout.isMeshLayoutMechanism,
                isResponsive: santaComponents.santaTypesDefinitions.RendererModel.isResponsive,
                layoutContainerWrapperClassName: santaComponents.santaTypesDefinitions.Layout.layoutContainerWrapperClassName,
                containerStyle: santaComponents.santaTypesDefinitions.CompDesign.containerStyle,
                // Column props
                bgStyle: PropTypes.object,
                rootStyle: PropTypes.object,
                inlineParentStyle: PropTypes.object,
                inlineStyle: PropTypes.object,
                isMobileResponsive: PropTypes.bool
            },
            statics: {
                compSpecificIsDomOnlyOverride: () => _.get(options, 'isDomOnlyUpdateAllowed', true),
                behaviors: mediaCommon.mediaBehaviors.fill
            },

            getDefaultSkinName() {
                return 'wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer';
            },

            getA11yProps() {
                const a11y = _.get(this.props, ['compData', 'a11y'], {});
                const attributes = ['label', 'live', 'atomic', 'hidden', 'level', 'current'].reduce((acc, curr) => {
                    acc[`aria-${curr}`] = a11y[curr];
                    return acc;
                }, {});

                const {role, tabindex: tabIndex} = a11y;
                return _.omitBy(_.assign({role, tabIndex}, attributes), _.isUndefined);
            },

            getSkinProperties() { // eslint-disable-line complexity
                const bgStyle = this.props.bgStyle;
                const isMobileResponsive = Boolean(this.props.isMobileResponsive);
                const {isMeshLayoutMechanism} = this.props;
                const a11yProps = this.getA11yProps();

                const childrenRendererProps = {contentArea: this.props.contentArea};
                if (isMeshLayoutMechanism && this.props.leavingChildrenIds && this.lastMeshParams) {
                    childrenRendererProps.overrides = {
                        components: [
                            ...this.props.meshParams.components,
                            ..._.filter(this.lastMeshParams.components, ({id}) => _.includes(this.props.leavingChildrenIds, id))
                        ]
                    };
                }

                if (options.fitToContentHeight) {
                    _.set(childrenRendererProps, ['overrides', 'fitToContentHeight'], true);
                }

                const mediaContainerChildren = this.getChildrenRenderer(childrenRendererProps);

                this.lastMeshParams = this.props.meshParams;
                const preventSubmit = e => e.preventDefault();
                return {
                    '': _.assign(
                        {style: getRootStyle(this.props.rootStyle, isMeshLayoutMechanism)},
                        this.props.contentArea && {'data-content-width': this.props.contentArea.width},
                        isMobileResponsive && {'data-mobile-responsive': true},
                        isMeshLayoutMechanism && {'data-is-mesh': true},
                        a11yProps),
                    background: this.props.isResponsive ? {} : this.createFillLayers({
                        mediaDimensions: this.props.mediaDimensions,
                        bgStyle
                    }),
                    container: {
                        className: this.props.layoutContainerWrapperClassName,
                        style: getContainerStyle(this.props)
                    },

                    inlineContentParent: {
                        className: this.props.layoutContainerWrapperClassName,
                        style: getInlineParentStyle(this.props, this.getIsFullScreenAndFixed())
                    },

                    inlineContent: this.getInlineContent({
                        children: mediaContainerChildren,
                        style: getInlineStyle(this.props)
                    }),
                    form: {
                        onSubmit: preventSubmit
                    }
                };
            }
        };
    }

    return {
        createMediaContainer
    };
});
